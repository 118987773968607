import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Button } from "@material-ui/core";
import "./recruiters.css";
import { Loader } from '@progress/kendo-react-indicators';
const AllNotApprovedRecruitersKendoTable = ({
  allNotApprovedRecruiters,
  approveRecruiterHandler,
  revokeRecruiterHandler,
  isDataLoaded,
  setDataStateNew,
  dataStateNew,
  totalCount
}) => {
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [result, setResult] = React.useState(allNotApprovedRecruiters?.result || []);
  const dataStateChange = (event) => {
    setResult(process(allNotApprovedRecruiters, event.dataState));
    setDataState(event.dataState);
  
  };

  const pageChange = (e) => {    
    
    setDataStateNew({skip: e.page.skip, take: e.page.take });
  }

  useEffect(() => {


    setResult(process(allNotApprovedRecruiters, dataState));

    const country = "country";
    const rqDate = "requestDate"
    for (let i = 0; i < allNotApprovedRecruiters.length; i++) {
      allNotApprovedRecruiters[i][country] = allNotApprovedRecruiters[i]?.profile ?
        (allNotApprovedRecruiters[i]?.profile?.locationCountry ?
          JSON.parse(allNotApprovedRecruiters[i]?.profile?.locationCountry)?.name : "") : ""

      allNotApprovedRecruiters[i][rqDate] = allNotApprovedRecruiters[i].createdAt != null ?
        handleRequestDateLuxon(allNotApprovedRecruiters[i])
        : "N/A"
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotApprovedRecruiters]);

  const handleAction = (props) => {
    return (
      <td>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => approveRecruiterHandler(e, props.dataItem)}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => revokeRecruiterHandler(e, props.dataItem)}
          className={"revokeBtn"}
        >
          Revoke
        </Button>

      </td>
    );
  };

  const handleDate = (props) => {
    return (
      <td>
        {DateTime.fromISO(props.dataItem.createdAt)
          .toFormat("MM/dd/yyyy")
          .toString()}
      </td>
    );
  };

  const handlePhoneNumber = (props) => {
    return (
      <td>{props.dataItem.phoneNumber ? props.dataItem.phoneNumber : ""}</td>
    );
  };

  // const handleCountry = (props) => {
  //   return (
  //     <td>
  //       {props.dataItem?.profile?.locationCountry
  //         ? JSON.parse(props.dataItem?.profile?.locationCountry).name
  //         : ""}
  //     </td>
  //   );
  // };

  // Luxon DateTime
  const handleRequestDateLuxon = (data) => {
    const date = data.createdAt;
    const formatted = DateTime.fromISO(date)
      .toLocal()
      .startOf("day")
      .toJSDate();

    return formatted ? formatted : "N/A";
  }

  let columns = [
    { id: 0, name: "expanded", title: " ", filterable: true, width: "200px" },
    {
      id: 1,
      name: "action",
      title: "Action",
      filterable: false,
      cell: handleAction,
      width: "210px",
    },
    {
      id: 2,
      name: "firstName",
      title: "First Name",
      filterable: true,
      width: "200px",
    },
    {
      id: 3,
      name: "lastName",
      title: "Last Name",
      filterable: true,
      width: "200px",
    },
    { id: 4, name: "email", title: "Email", filterable: true, width: "350px" },
    {
      id: 5,
      name: "role.roleName",
      title: "User role",
      filterable: true,
      width: "200px",
    },
    {
      id: 6,
      name: "organizationnameduringsignup",
      title: "Recruitment Organization",
      filterable: true,
      width: "200px",
    },
    {
      id: 7,
      name: "phoneNumber",
      title: "Phone Number",
      cell: handlePhoneNumber,
      width: "200px",
      filterable: true,
      filter: "numeric",
    },
    {
      id: 8,
      name: "country",
      title: "Country",
      filterable: true,
      width: "200px",
    },
    {
      id: 9,
      name: "requestDate",
      title: "Request Date",
      filterable: true,
      width: "200px",
      filter: "date",
      format: "{0:d},{0:t}",
      cell: handleDate,
    },
  ];

  return (
    <Grid
      style={{
        maxHeight: "75Vh",
        width: "100%",
        overflow: "hidden"
      }}
      sort={dataState.sort}
      sortable={true}
      filter={dataState.filter}
      pageable={{
        buttonCount: 10,
        info: true,
        previousNext: true,
        pageSizes: true,
      }}
      resizable={true}
      skip={dataStateNew.skip}
      take={dataStateNew.take}
      data={result}
      total={totalCount}
      onDataStateChange={dataStateChange}
      onPageChange={(e) => pageChange(e)}
    >
      {
        !isDataLoaded && (
          <GridNoRecords>
            <div className='col-4'>
              <br />
              <Loader size='large' themeColor="info" type="converging-spinner" />
            </div>
          </GridNoRecords>
        )}


      {columns?.slice(1).map((column) => {
        return (
          <Column
            key={column.id}
            field={column.name}
            title={column.title}
            cell={column.cell}
            width={column.width}
            filterable={column.filterable}
            filter={column.filter}
          />
        );
      })}
    </Grid>
  );
};

export default AllNotApprovedRecruitersKendoTable;
