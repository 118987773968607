import React, { forwardRef, useImperativeHandle, useState } from 'react'
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { AddCircle, Cancel } from '@material-ui/icons';
import configJSON from "../../Config.json";
import { Autocomplete } from '@material-ui/lab';
import assignTeamLeaderRequisition from '../../apis/createRequisition/assignTeamLeaderRequisition';
import checkRequisitionExists from '../../apis/checkRequisitionExists/checkRequisitionExists';

const TeamLeaderRequistionAssignment = forwardRef((props, ref) => {

    const {
        modalIsOpen,
        closeModal,
        divisionByOrgData,
        requisitionResponseData,
        status,
        requisitionId,
        getRequisitionDetail,
        setSelectedTeam,
        selectedTeam,
        openRecruiterRequisitionModal,
        setmodalIsOpen,
        recruitmentpartnerId,
        displayName,
        id,
        userType
    } = props
    const [teamLeaderAssigment, setTeamLeaderAssigment] = useState([
        {
            "division": "",
            "recruitmentLeader": "",
            "team": "",
            "teamLeaderId": "",
            "noOfPositions": "",
            "isBorrowed": false,
            "isBorrowedExternally": false,
            "isBorrowedFromSameDiv": false,
            "isRequisitionAssigned": false,
        },
    ])
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false);
    const [isTeamLeaderSelectOpen, setIsTeamLeaderSelectOpen] = useState(false);
    // const [teamLeaderOptions, setTeamLeaderOptions] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);

    const addTeamLeaderAssigment = () => {
        const row = getDefaultDivisionRecruiterLeader();
        setTeamLeaderAssigment([...teamLeaderAssigment, row]);
    }

    const divisionChangeHandlerAssignTeamLeader = async (value, index) => {

        let newArr = [...teamLeaderAssigment]
        if (value) {
            newArr[index].division = value
            if (value._id !== requisitionResponseData.divisionId) {
                newArr[index].isBorrowed = true
                newArr[index].isBorrowedExternally = true
            }
            else {
                newArr[index].isBorrowed = false
                newArr[index].isBorrowedExternally = false
                newArr[index].isBorrowedFromSameDiv = false
            }
        }
        else {
            newArr[index].division = ""
        }
        newArr[index].recruitmentLeader = ""
        newArr[index].team = ""
        newArr[index].teamLeaderId = ""

        setTeamLeaderAssigment(newArr)
    }

    const extraLeaderAssignmentClose = (index, item) => {
        if (item.isRequisitionAssigned) {
            NotificationManager.error("Team leader already assigned to an open requisition cannot be removed", "Error", 3500);
            return;
        }
        let originalData = [...teamLeaderAssigment];
        originalData.splice(index, 1);
        setTeamLeaderAssigment(originalData);
    }

    const getRecruiterLeaderByDivision = (division) => {
        let recLeaders = [];
        if (division) {
            for (let i = 0; i < division?.teamDetails?.length; i++) {
                const item = division.teamDetails[i];
                recLeaders.push(item.recruiterLeaderDetails);
            }
        }
        return recLeaders;
    };


    const recruiterLeaderChangeHandler_assignTeamLead = (value, index) => {
        let newArr = [...teamLeaderAssigment]
        if (value) {
            newArr[index].recruitmentLeader = value
            if (newArr[index].isBorrowed && newArr[index].isBorrowedExternally) {
                newArr[index].isBorrowedFromSameDiv = false
            }
            else {
                if (value.id !== requisitionResponseData?.assignedTo) {
                    newArr[index].isBorrowedFromSameDiv = true
                    newArr[index].isBorrowed = true
                }
                else {
                    newArr[index].isBorrowedFromSameDiv = false
                }
            }
        }
        else {
            newArr[index].recruitmentLeader = ""
        }
        newArr[index].team = ""
        newArr[index].teamLeaderId = ""
        setTeamLeaderAssigment(newArr)
    }

    const getTeamOptions = (selectedData) => {
        let arr = [];

        if (selectedData?.recruitmentLeader) {
            let divisionData = divisionByOrgData.find(item => item._id === selectedData.division._id);
            if (divisionData) {
                let teamDetails = divisionData.teamDetails.filter(item => item?.recruiterLeaderDetails?.id === selectedData?.recruitmentLeader?.id);
                if (teamDetails.length > 0) {
                    let allTeamDetails = teamDetails[0].teamDetails;
                    allTeamDetails = allTeamDetails.filter((teamItem) => teamItem.isActive === true);
                    let unAssignedTeam = allTeamDetails.filter((team) => {
                        return !teamLeaderAssigment.some((assignment) => assignment.team?._id === team._id);
                    });

                    arr = unAssignedTeam;
                }
            }
        }
        return arr;
    }


    const teamLeaderChangeHandler = (value, index) => {
        let newArr = [...teamLeaderAssigment]
        if (value) {
            newArr[index].team = value
            newArr[index].teamLeaderId = value.teamLeaderId
            // newArr[index].teamLeaderUpdate = 'updated'
        }
        else {
            newArr[index].team = ""
            newArr[index].teamLeaderId = ""
        }
        setTeamLeaderAssigment(newArr)
    }

    const openingHandleChange = (e, index) => {
        let newArr = [...teamLeaderAssigment]
        if (e.target.value) {
            newArr[index].noOfPositions = parseInt(e.target.value);
        } else {
            newArr[index].noOfPositions = "";
        }
        setTeamLeaderAssigment(newArr)
    }

    const getRemovedTeamLeaderIds = () => {
        let removedTeamIds = [];
        let notificationList = [];
        let teamLeaderLogList = [];
        let teamLeaderAssignmentData = requisitionResponseData?.teamLeaderRequisitionDetails || [];
        if (teamLeaderAssignmentData.length > 0) {
            teamLeaderAssignmentData.forEach((item) => {
                let noOfPositions;
                // let isExist = teamLeaderAssigment.some((assignment) => assignment.teamLeader?.id === item?.team?.teamLeaderId);
                let isExist = teamLeaderAssigment.some((assignment) => {

                    if (assignment.team?._id === item?.team?.teamId) {
                        noOfPositions = parseInt(assignment.noOfPositions) // Store the position when isExist is true
                        return true;  // Return true to indicate that the recruiter exists
                    }
                    return false;  // Return false if recruiter doesn't match
                });
                if (!isExist) {
                    const tlobj = {}
                    tlobj.id = item?.team?.teamLeaderId;
                    // tlobj.email = item?.teamLeaderDetails?.email;
                    // tlobj.displayName = item?.teamLeaderDetails?.displayName;
                    // tlobj.message = "Requisition with Reference ID : " + requisitionResponseData.reqreferenceId + " " + " is reassigned by " + displayName;
                    tlobj.status = -1 //
                    removedTeamIds.push(item?.team?.teamId)
                    if (item?.team?.teamLeaderId) {
                        notificationList.push(tlobj)
                    }
                } else {
                    if (item?.no_of_positions != noOfPositions) {
                        const tlobj = {}
                        tlobj.id = item?.team?.teamLeaderId;
                        // tlobj.email = item?.teamLeaderDetails?.email;
                        // tlobj.displayName = item?.teamLeaderDetails?.displayName;
                        // tlobj.message = "Requisition with Reference ID : " + requisitionResponseData.reqreferenceId + " " + " is reassigned by " + displayName;
                        tlobj.status = 1 //already added
                        if (item?.team?.teamLeaderId) {
                            notificationList.push(tlobj)
                            teamLeaderLogList.push(item?.team?.teamLeaderId)
                        }
                    }
                }
            });
            const newTlList = teamLeaderAssigment.filter(item2 => !teamLeaderAssignmentData.some(item1 => item1?.team?.teamId === item2?.team?._id));
            newTlList.forEach((item) => {
                const tlobj = {}
                tlobj.id = item?.teamLeaderId;
                // tlobj.email = item?.teamLeader?.email;
                // tlobj.displayName = item?.teamLeader?.displayName;
                // tlobj.message = "Requisition with Reference ID : " + requisitionResponseData.reqreferenceId + " " + " is reassigned by " + displayName;
                tlobj.status = 0 //newly added

                if (item.teamLeaderId) {
                    notificationList.push(tlobj)
                    teamLeaderLogList.push(item?.teamLeaderId)
                }
            })
        } else {
            teamLeaderAssigment.forEach((item) => {
                const tlobj = {}
                tlobj.id = item?.teamLeaderId;
                // tlobj.email = item?.teamLeader?.email;
                // tlobj.displayName = item?.teamLeader?.displayName;
                // tlobj.message = "Requisition with Reference ID : " + requisitionResponseData.reqreferenceId + " " + " is reassigned by " + displayName;
                tlobj.status = 0  ////newly added

                if (item.teamLeaderId) {
                    notificationList.push(tlobj)
                    teamLeaderLogList.push(item?.teamLeaderId)
                }
            })
        }



        return { "removedTeamIds": removedTeamIds, "notificationList": notificationList, "teamLeaderLogList": teamLeaderLogList }
    }

    const handleAssignTeamLeader = async () => {
        let isvalid = true;
        let assignedPositions = 0;

        let teamLeaderAssigmentData = [...teamLeaderAssigment];
        const openStatus = status.find(item => item.statusCode === configJSON?.requisitionStatusCodes?.OPEN);

        for (let i = 0; i < teamLeaderAssigmentData.length; i++) {
            const item = teamLeaderAssigmentData[i];
            if (item.division === "" || item.recruitmentLeader === "" || item.noOfPositions === "") {
                NotificationManager.error("Please fill in all the fields", "Error", 2500);
                isvalid = false;
                break;
            }
            else if (item.noOfPositions <= 0) {
                NotificationManager.error("No of positions should be greater than 0", "Error", 2500);
                isvalid = false;
                break;
            }
            let payload = {
                // teamLeaderId: item?.teamLeader?.id,
                teamId: item?.team?._id,
                recruitmentpartnerId: recruitmentpartnerId,
                validation: "Team Assignment",
                requisitionDetailId: requisitionResponseData?.recruiterLeaderRequisitionDetails?.requisitionDetailId,
            };

            try {
                let response = await checkRequisitionExists(payload);

                if (response.status === 200) {
                    if (response.data.data.length === 0) {
                        const subStatus_unassigned = openStatus?.subList?.find(item => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED);

                        let candidateStaus = {
                            subCode: subStatus_unassigned.subCode,
                            statusCode: openStatus.statusCode,
                            statusCodeId: openStatus._id,
                            subCodeId: subStatus_unassigned._id
                        }

                        let teamLeaderRequisitionDetails = requisitionResponseData?.teamLeaderRequisitionDetails || [];
                        teamLeaderRequisitionDetails.forEach(element => {
                            if (element.team.teamId === item.team._id) {
                                candidateStaus = element.status
                            }
                        });
                        teamLeaderAssigmentData[i].status = candidateStaus;
                    }
                    else if (response.data.data[0]?.candidateRequisitions?.length > item.noOfPositions) {
                        let candidateCount = response.data.data[0]?.candidateRequisitions?.length;
                        NotificationManager.error(
                            `Team (${item?.team?.teamName}) already has ${candidateCount} candidates as selected or started. Assigned positions cannot be fewer than ${candidateCount}`,
                            "Error",
                            4500
                        );
                        isvalid = false;
                        break;
                    }
                    else if (response.data.data[0]?.candidateRequisitions?.length === item.noOfPositions) {
                        const subStatus_covered = openStatus?.subList?.find(item => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.COVERED);
                        teamLeaderAssigmentData[i].status = {
                            subCode: subStatus_covered.subCode,
                            statusCode: openStatus.statusCode,
                            statusCodeId: openStatus._id,
                            subCodeId: subStatus_covered._id
                        }
                    }
                    else if (response.data.data[0]?.candidateRequisitions?.length < item.noOfPositions) {
                        const subStatus_uncovered = openStatus?.subList?.find(item => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNCOVERED);
                        teamLeaderAssigmentData[i].status = {
                            subCode: subStatus_uncovered.subCode,
                            statusCode: openStatus.statusCode,
                            statusCodeId: openStatus._id,
                            subCodeId: subStatus_uncovered._id
                        }
                    }
                    else {
                        const subStatus_uncovered = openStatus?.subList?.find(item => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNCOVERED);
                        teamLeaderAssigmentData[i].status = {
                            subCode: subStatus_uncovered.subCode,
                            statusCode: openStatus.statusCode,
                            statusCodeId: openStatus._id,
                            subCodeId: subStatus_uncovered._id
                        }
                    }
                }
            } catch (error) {
                NotificationManager.error('Something went wrong', "Error", 2000);
            }

            assignedPositions += item.noOfPositions;
        }

        if (assignedPositions > requisitionResponseData?.recruiterLeaderRequisitionDetails?.openings) {
            NotificationManager.error(`Total assigned positions should not be greater than ${requisitionResponseData?.recruiterLeaderRequisitionDetails?.openings}`, "Error", 2000);
            isvalid = false;
            return;
        }
        if (isvalid) {

            let mappedData = [];

            for (let i = 0; i < teamLeaderAssigmentData.length; i++) {
                let item = teamLeaderAssigmentData[i];

                let mappedItem = {
                    requisitionId: requisitionId,
                    requisitionDetailId: requisitionResponseData?.recruiterLeaderRequisitionDetails?.requisitionDetailId,
                    requisitionLeaderId: requisitionResponseData?.recruiterLeaderRequisitionDetails?._id,
                    isBorrowed: item.isBorrowed,
                    isBorrowedExternally: item.isBorrowedExternally,
                    isBorrowedFromSameDiv: item.isBorrowedFromSameDiv,
                    no_of_positions: item.noOfPositions,
                    team: {
                        teamId: item?.team?._id,
                        teamLeaderId: item?.teamLeaderId,
                    },
                    division: {
                        divisionId: item.division._id,
                        recruitmentLeaderId: item.recruitmentLeader.id,
                    },
                    status: item.status,
                    isActive: true,
                };
                mappedData.push(mappedItem);
            }

            let teamLeadObj = getRemovedTeamLeaderIds();
            let removedTeamIds = teamLeadObj.removedTeamIds;
            let isrlnf = userType === "Recruitment Leader" ? false : true

            let payload = {
                mappedData: mappedData,
                requisitionDetailId: requisitionResponseData?._id,
                removedTeamIds: removedTeamIds,
                requisitionId: requisitionId,
                recruitmentpartnerId: recruitmentpartnerId,
                notificationList: teamLeadObj.notificationList,
                teamLeaderLogList: teamLeadObj.teamLeaderLogList,
                senderId: id,
                reqreferenceId: requisitionResponseData.reqreferenceId,
                displayName,
                userRecruitmentpartnerId: recruitmentpartnerId,
                openings: requisitionResponseData?.openings,
                userId: id,
                recruiterLeaderEmail: requisitionResponseData.recruiterLeaderDetails.email,
                recruiterLeaderId: requisitionResponseData.recruiterLeaderDetails.id,
                isrlnf
            }

            try {
                setIsUserDataUpdating(true);
                let response = await assignTeamLeaderRequisition(payload)
                if (response.status === 200) {
                    NotificationManager.success(response.data.message, "Success", 2000);
                    setIsUserDataUpdating(false);
                    closeModal();
                    getRequisitionDetail();
                }

            } catch (error) {
                if (error.response.status === 400) {
                    NotificationManager.error(error?.response?.data?.errMessage, "Error", 2500);
                    setIsUserDataUpdating(false);
                }
                else {
                    NotificationManager.error('Something went wrong', "Error", 2000);
                }
            }
        }
    }

    const setTemLeaderRequisitionsData = async () => {
        const teamLeaderAssignmentData = requisitionResponseData?.teamLeaderRequisitionDetails || [];

        if (teamLeaderAssignmentData.length > 0) {
            const updatedAssignments = [];

            for (let i = 0; i < teamLeaderAssignmentData.length; i++) {
                const item = teamLeaderAssignmentData[i];
                const division = divisionByOrgData.find(division => division._id === item?.division?.divisionId);
                const recruitmentLeader = getRecruiterLeaderByDivision(division)?.find(
                    recLeader => recLeader.id === item?.division?.recruitmentLeaderId
                );
                // const teamLeader = teamLeaderAssignmentData[i]?.teamLeaderDetails;
                let teamDetails = teamLeaderAssignmentData[i]?.teamDetails;
                let teamLeaderId = teamLeaderAssignmentData[i]?.team?.teamLeaderId;

                let isRequisitionAssigned = true

                if(item?.status?.statusCode === configJSON?.requisitionStatusCodes?.OPEN 
                    && item.status?.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNASSIGNED){
                    isRequisitionAssigned = false
                }

                updatedAssignments.push({
                    division,
                    recruitmentLeader,
                    team: teamDetails,
                    teamLeaderId,
                    noOfPositions: item.no_of_positions,
                    isBorrowed: item.isBorrowed,
                    isBorrowedExternally: item.isBorrowedExternally,
                    isBorrowedFromSameDiv: item.isBorrowedFromSameDiv,
                    isRequisitionAssigned
                });
            }


            setTeamLeaderAssigment(updatedAssignments);
        }

        else {
            let obj = getDefaultDivisionRecruiterLeader();
            setTeamLeaderAssigment([obj]);
        }
    };

    const getDefaultDivisionRecruiterLeader = () => {
        let defaultDivision = divisionByOrgData.find(division => division._id === requisitionResponseData?.divisionId);
        let defaultRecuitmentLeader = getRecruiterLeaderByDivision(defaultDivision)?.find(recLeader => recLeader.id === requisitionResponseData?.recruiterLeaderRequisitionDetails?.recruiterLeaderId);
        return {
            "division": defaultDivision,
            "recruitmentLeader": defaultRecuitmentLeader,
            "team": "",
            "teamLeaderId": "",
            "noOfPositions": "",
            "isBorrowed": false,
            "isBorrowedExternally": false,
            "isBorrowedFromSameDiv": false,
            "isRequisitionAssigned": false,
        }
    }

    const clearTeamLeaderRequistionData = () => {
        setTeamLeaderAssigment([
            {
                "division": "",
                "recruitmentLeader": "",
                "team": "",
                "teamLeaderId": "",
                "noOfPositions": "",
                "isBorrowed": false,
                "isBorrowedExternally": false,
                "isBorrowedFromSameDiv": false,
                "isRequisitionAssigned": false,
            },
        ]);
    }

    const handleRecruiterAssignment = () => {
        setIsTeamLeaderSelectOpen(true);
        let selectedTeamData = teamLeaderAssigment.map((item) => item.team);
        setTeamOptions(selectedTeamData);
        setmodalIsOpen(false);
    }

    const clearTeamLeaderAssignmentData = () => {
        setTeamOptions([]);
        setIsTeamLeaderSelectOpen(false);
    }


    const handleTeamChange_RecuriterAssignment = (e, value) => {
        if (value) {
            setSelectedTeam(value);
        }
        else {
            setSelectedTeam({});
        }

    }

    const closeTeamLeadSelectModal = () => {
        setIsTeamLeaderSelectOpen(false);
        setmodalIsOpen(true);
    }

    const handleNext_TeamLeaderSelect = () => {
        setIsTeamLeaderSelectOpen(false);
        openRecruiterRequisitionModal();
    }

    //  Used to make the function available to the parent component
    useImperativeHandle(ref, () => ({
        setTemLeaderRequisitionsData,
        clearTeamLeaderRequistionData,
        handleRecruiterAssignment,
        clearTeamLeaderAssignmentData
    }));

    return (
        <>
            <Dialog
                onClose={closeModal}
                aria-labelledby="customized-dialog-title"
                maxWidth='xl'
                open={modalIsOpen}
                className='dialogBox_assignRequisition'
            >
                <DialogTitle id="customized-dialog-title" className="header" onClose={closeModal}>
                    <div className='header_top'>
                        Team Assignment
                    </div>
                </DialogTitle>
                <DialogContent className='assignRequisitionContainer'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Division*</TableCell>
                                <TableCell>Recruitment Leader*</TableCell>
                                <TableCell>Team*</TableCell>
                                <TableCell>No of positions*</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {teamLeaderAssigment.map((item, i) => (
                                <TableRow>
                                    <TableCell>
                                        <FormControl fullWidth variant="outlined">
                                            <Autocomplete
                                                id="multiple-limit-tags"
                                                className='input-text-3'
                                                options={divisionByOrgData}
                                                getOptionLabel={(option) => option.divisionName ? option?.divisionName : ""}
                                                value={item.division || ""}
                                                filterSelectedOptions
                                                disableClearable
                                                onChange={(e, val) => divisionChangeHandlerAssignTeamLeader(val, i)}
                                                disabled={item.isRequisitionAssigned}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        required={true}
                                                        placeholder="Select Division"
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </TableCell>

                                    <TableCell>
                                        <FormControl fullWidth variant="outlined">
                                            <Autocomplete
                                                id="multiple-limit-tags"
                                                className='input-text-3'
                                                options={getRecruiterLeaderByDivision(item.division)}
                                                getOptionLabel={(option) =>
                                                    option.email ? option?.email : ""
                                                }
                                                value={item.recruitmentLeader || ""}
                                                filterSelectedOptions
                                                disableClearable
                                                // disabled={item.isRequisitionAssigned || (item?.teamLeader &&item?.teamLeaderUpdate!=='updated')}
                                                disabled={item.isRequisitionAssigned}
                                                onChange={(e, val) => recruiterLeaderChangeHandler_assignTeamLead(val, i)}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        required={true}
                                                        placeholder="Select Recruitment Leader"
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </TableCell>

                                    <TableCell>
                                        <FormControl fullWidth variant="outlined">
                                            <Autocomplete
                                                id="multiple-limit-tags"
                                                className='input-text-3'
                                                options={getTeamOptions(item)}
                                                getOptionLabel={(option) =>
                                                    option.teamName ? option?.teamName : ""
                                                }
                                                value={item?.team || ""}
                                                filterSelectedOptions
                                                disableClearable
                                                disabled={item.isRequisitionAssigned}
                                                onChange={(e, val) => teamLeaderChangeHandler(val, i)}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        required={true}
                                                        placeholder="Select Team"
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </TableCell>

                                    <TableCell className='openings_TeamLeaderRequisitionContainer'>
                                        <FormControl variant="filled">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                type='number'
                                                placeholder="Enter positions"
                                                className='noOfPosition_teamLeaderAssignment'
                                                value={item.noOfPositions || ""}
                                                onChange={(e) => openingHandleChange(e, i)}
                                            />
                                        </FormControl>

                                        {teamLeaderAssigment.length > 1 ?
                                            <Cancel
                                                className="cancelIcon_teamLeaderReq"
                                                onClick={(e) => extraLeaderAssignmentClose(i, item)}
                                            />
                                            : ""}
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <AddCircle
                                        color="primary"
                                        onClick={(e) => addTeamLeaderAssigment(e)}
                                        className="addIcon_teamLeaderAssignment"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>

                <DialogActions className="dialog-actionbutton-assignTeamLeader">

                    {requisitionResponseData?.teamLeaderRequisitionDetails?.length > 0 && (
                        <button
                            className="recruiterAssignmentButton"
                            onClick={(e) => handleRecruiterAssignment()}
                        >
                            Recruiter Assignment
                        </button>
                    )}

                    <button className="cancel-button-assignTeamLeader" onClick={() => closeModal()}>
                        Cancel
                    </button>
                    <button
                        className={isUserDataUpdating ? 'updateButton-assignTeamLeader_disabled' : 'updateButton-assignTeamLeader'}
                        onClick={(e) => handleAssignTeamLeader()}
                        disabled={isUserDataUpdating}
                    >
                        <span>Save</span>
                        {isUserDataUpdating && (
                            <CircularProgress className="circularProgress_updateButton" />
                        )}
                    </button>

                </DialogActions>

            </Dialog>

            <Dialog
                onClose={closeTeamLeadSelectModal}
                aria-labelledby="customized-dialog-title"
                maxWidth='md'
                fullWidth={true}
                open={isTeamLeaderSelectOpen}
                className='dialogBox_assignRequisition'
            >
                <DialogTitle id="customized-dialog-title" className="header">
                    <div className='header_top'>
                        Recruiter Assigment For
                    </div>
                </DialogTitle>

                <DialogContent className='selectedTeamLeaderOnBehalfContainer'>

                    <div className='teamLeaderHeading_behalf'>Team </div>

                    <Autocomplete
                        id="multiple-limit-tags"
                        options={teamOptions}
                        getOptionLabel={(option) =>
                            option.teamName ? option?.teamName : ""
                        }
                        value={selectedTeam}
                        filterSelectedOptions
                        onChange={(e, value) => handleTeamChange_RecuriterAssignment(e, value)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Team"
                            />
                        }
                    />
                </DialogContent>

                <DialogActions className="dialog-actionbutton-assignTeamLeader">
                    <button className="cancel-button-assignTeamLeader" onClick={() => closeTeamLeadSelectModal()}>
                        Cancel
                    </button>
                    <button
                        className={selectedTeam?._id ? 'updateButton-assignTeamLeader' : 'updateButton-assignTeamLeader_disabled'}
                        disabled={!selectedTeam?._id}
                        onClick={(e) => handleNext_TeamLeaderSelect()}
                    >
                        <span>Next</span>
                    </button>

                </DialogActions>


            </Dialog>

            <NotificationContainer />
        </>
    )
})

export default TeamLeaderRequistionAssignment