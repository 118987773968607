import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Grid as GridDiv,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import recruiterActivityReports from "../../apis/requisitionReports/recruiterActivityReport";
import debounce from "lodash/debounce";
import configJSON from "../../Config.json";
import "./requisitionReports.css";
import * as XLSX from "xlsx";
import {
  NotificationManager,
} from "react-notifications";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import config from "../../Config.json"

const useStyles = makeStyles((theme) => ({
  odd: {
    background: "#F6F6F6 !important",
  },
  even: {
    background: "#ffffff !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  waitingText: {
    marginLeft: "5px",
  },
  clickable: {
    cursor: 'pointer',
  },
  nonClickable: {
    cursor: 'not-allowed',
  },
}));


const RecruitersActivity = (props) => {
  const {
    userType,
    userIdType,
    userRecruitmentpartnerId,
    triggerAction,
    resetTrigger,
    startSelectedDate,
    endSelectedDate,
    additionalFilterData,
    setIsfilterSearch,
    isfilterSearch
  } = props;

  const [isSearching, setIsSearching] = useState(true);
  const [searchTerms, setSearchTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const classes = useStyles();
  const [isExporting, setIsExporting] = useState(false);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  });

  const [dataResult, setDataResult] = useState([]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    setIsSearching(true);
    setDataResult([]);
    const terms =
      filters && filters.length > 0
        ? filters
          .filter(({ value }) => value && value !== "") // Filter out objects where value is empty, null, or white spaces
          .map(({ field, value, operator }) => ({ field, value, operator }))
        : [];

    setSearchTerms(terms);

    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
    setIsSearching(true);
  };

  useEffect(() => {
    let setDefaultValue = {
      skip: 0,
      take: 10,
      filter: {
        logic: "and",
        filters: [],
      },
    };
    setDataState(setDefaultValue);

  }, []);

  function formatDateOnly(dateString) {
    const date = new Date(dateString);

    // Extract the year, month, and day in the local timezone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    const test = `${year}-${month}-${day}`;

    return test;
  }

  let hasAdditionalFilterData = Object.values(additionalFilterData).some(
    (value) => Array.isArray(value) ? value.length > 0 : !!value
  );

  const debouncedSearch = useCallback(
    debounce(
      async (
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userId,
        additionalFilterData
      ) => {
        if (!startSelectedDate || !endSelectedDate) {
          NotificationManager.error("Please enter a valid date", "Error", 2500);
        } else {
          setLoading(true);
          let payload = {
            orgId: userRecruitmentpartnerId,
            page: pageNumber,
            size: takeNumberOfResult,
            formattedDatestart: formatDateOnly(startSelectedDate),
            formattedDateend: formatDateOnly(endSelectedDate),
            isExport: false,
            userId: userId,
            role: userType,
            additionalFilter: {
              clientName: additionalFilterData.clientName,
              divisionId: additionalFilterData.divisionId,
            }
          };
          const search = await recruiterActivityReports(payload);

          if (search?.data?.length <= 0) {
            setLoading(false);
            setDataResult(search.data[0]);
            setTotalResult(0);
            setIsSearching(false);
          }
          if (search?.data?.length > 0) {
            let allReponse = search.data;
            setDataResult(allReponse);
            setIsfilterSearch(false)
            if (search.totalCount[0].totalCount) {
              setTotalResult(search.totalCount[0].totalCount);
              setIsSearching(false);
            }

          }
        }
      },
      1000
    ),
    []
  );
  useEffect(() => {
    if (triggerAction) {
      callApiToGenerateExcel(); // Perform the action
      resetTrigger(); // Reset the trigger to avoid repeated execution
    }
  }, [triggerAction, resetTrigger]);
  useEffect(() => {

    if (!startSelectedDate || !endSelectedDate) {
      NotificationManager.error(
        "Please enter a valid date",
        "Error",
        2500
      );

    }

    else {
      setIsSearching(true);
      setDataResult([]);


     // Reset page number to 1 if hasAdditionalFilterData is true
     if (hasAdditionalFilterData) {
      setPageNumber(1);
    }
      debouncedSearch(
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userIdType,
        additionalFilterData
      );
      setIsfilterSearch(false)
    }


  }, [
    searchTerms,
    pageNumber,
    takeNumberOfResult,
    startSelectedDate,
    endSelectedDate,
    isfilterSearch
  ]);

  const callApiToGenerateExcel = async () => {
    if (!startSelectedDate || !endSelectedDate) {
      NotificationManager.error("Please enter a valid date", "Error", 2500);
    } else {
      setIsExporting(true);
      // const formattedDatestart = startSelectedDate.toISOString();
      // const formattedDateend = endSelectedDate.toISOString();

      let maxSize = 100;
      let end = Math.ceil(totalResult / maxSize);
      let pageStart = 1;
      let pageEnd = maxSize;
      let exportArr = [];
      for (let start = 0; start < end; start++) {
        let payload = {
          orgId: userRecruitmentpartnerId,
          isExport: true,
          page: pageStart,
          size: maxSize,
          formattedDatestart: formatDateOnly(startSelectedDate),
          formattedDateend: formatDateOnly(endSelectedDate),
          userId: userIdType,
          role: userType,
          additionalFilter: {
            clientName: additionalFilterData.clientName,
            divisionId: additionalFilterData.divisionId,
          }
        };


       
        const allDetails = await recruiterActivityReports(payload);
        for (let i = 0; i < allDetails.data.length; i++) {
          let element = allDetails.data[i];
          let data = {
            "Recruiter Name": element.recruiterDetails.displayName,
            "Email ID": element.recruiterDetails.email,
            "Requisition Assigned": element.assignedRequisitionsCount,
            Covered: element.covered,
            Uncovered: element.uncovered,
            Submitted: element.submitted,
            "Client Declined": element.clientDeclined,
            "L1 Interview": element.interviewScheduled,
            "L2 Interview": element.interviewScheduled_L2,
            "L3 Interview": element.interviewScheduled_L3,
            Selected: element.selected,
            "Candidate Declined": element.candidateDeclined,
            Started: element.started,
            Dropout: element.dropout,
          };
          exportArr.push(data);
        }
        pageStart = pageStart + maxSize;
      }
      if (exportArr.length === 0) {
        NotificationManager.error("No data present", "Error", 2500);
        setIsExporting(false);
      } else {
        const MAX_ROWS_PER_SHEET = 500;

        const wb = XLSX.utils.book_new();

        // Calculate the number of sheets needed
        const sheetCount = Math.ceil(exportArr.length / MAX_ROWS_PER_SHEET);

        // Loop to create sheets
        for (let sheetIndex = 0; sheetIndex < sheetCount; sheetIndex++) {
          // Get a subset of data for each sheet
          const startRow = sheetIndex * MAX_ROWS_PER_SHEET;
          const endRow = Math.min(
            (sheetIndex + 1) * MAX_ROWS_PER_SHEET,
            exportArr.length
          );
          const subsetData = exportArr.slice(startRow, endRow);

          // Convert JSON array to worksheet
          const ws = XLSX.utils.json_to_sheet(subsetData);

          // Append the sheet to the workbook
          XLSX.utils.book_append_sheet(wb, ws, `Sheet${sheetIndex + 1}`);
        }

        // Save the workbook to an Excel file
        XLSX.writeFile(wb, `RecruiterActivity.xlsx`, {
          bookType: "xlsx",
          type: "file",
        });
        setIsExporting(false);
      }
    }

    // Call the API to generate the Excel file
  };

  const recruiterActivitySkeleton = () => {
    let arr = [];
    for (let i = 0; i <= 10; i++) {
      arr.push(
        <Skeleton
          className={i % 2 === 0 ? classes.odd : classes.even}
          variant="rect"
          width="100%"
          height="50px"
        />
      );
    }
    return arr;
  };

  const history = useHistory()

  const clickableColumns = (event, code) => {
    // Use the code directly from fields
    const fieldStatus = code || "";

    const handleClick = () =>
      history.push('/requisition-report-details', {
        recId: event.dataItem["recruiterId"],
        field: fieldStatus,
        startDate: startSelectedDate.toISOString(),
        endDate: endSelectedDate.toISOString()
      });
    let value = parseInt(event.dataItem[event.field])

    return (
      <td
        onClick={value > 0 ? handleClick : () => { }}
        className={value > 0 ? classes.clickable : classes.nonClickable}
      >
        {event.dataItem[event.field]}
      </td>
    );
  };


  const fields = [
    {
      "title": "Recruiter Name",
      "field": "recruiterDetails.displayName",
      "onClick": false
    },
    {
      "title": "Email ID",
      "field": "recruiterDetails.email",
      "onClick": false
    },
    {
      "title": "Requisition Assigned",
      "field": "assignedRequisitionsCount",
      "onClick": true
    },
    {
      "title": "Covered",
      "field": "covered",
      "code": configJSON?.requisitionStatusCodes?.OPENSUBSTATUS.COVERED,
      "onClick": true
    },
    {
      "title": "Uncovered",
      "field": "uncovered",
      "code": configJSON?.requisitionStatusCodes?.OPENSUBSTATUS.UNCOVERED,
      "onClick": true
    },
    {
      "title": "Submitted",
      "field": "submitted",
      "code": configJSON?.candidateRequisitionStatusCode?.SUBMITTED,
      "onClick": true
    },
    {
      "title": "Client Declined",
      "field": "clientDeclined",
      "code": configJSON?.candidateRequisitionStatusCode?.CLIENT_DECLINED,
      "onClick": true
    },
    {
      "title": "L1 Interview",
      "field": "interviewScheduled",
      "code": configJSON?.candidateRequisitionStatusCode?.L1_Interview,
      "onClick": true
    },
    {
      "title": "L2 Interview",
      "field": "interviewScheduled_L2",
      "code": configJSON?.candidateRequisitionStatusCode?.L2_Interview,
      "onClick": true
    },
    {
      "title": "L3 Interview",
      "field": "interviewScheduled_L3",
      "code": configJSON?.candidateRequisitionStatusCode?.L3_Interview,
      "onClick": true
    },
    {
      "title": "Selected",
      "field": "selected",
      "code": configJSON?.candidateRequisitionStatusCode?.SELECTED,
      "onClick": true
    },
    {
      "title": "Candidate Declined",
      "field": "candidateDeclined",
      "code": configJSON?.candidateRequisitionStatusCode?.CANDIDATE_DECLINED,
      "onClick": true
    },
    {
      "title": "Started",
      "field": "started",
      "code": configJSON?.candidateRequisitionStatusCode?.STARTED,
      "onClick": true
    },
    {
      "title": "Dropout",
      "field": "dropout",
      "code": configJSON?.candidateRequisitionStatusCode?.DROPOUT,
      "onClick": true
    }
  ]

  return (
    <>
      <Backdrop className={classes.backdrop} open={isExporting}>
        <CircularProgress color="inherit" />
        <Typography className={classes.waitingText}>
          {" "}
          Please wait ...
        </Typography>
      </Backdrop>
      <GridDiv
        filterable={false}
        filter={dataState.filter}
        sortable={true}
        resizable={true}
        reorderable={true}
        pageable={{
          pageSizes: [10, 20, 50, 100],
          info: true,
          previousNext: true,
          buttonCount: 10,
        }}
        skip={dataState.skip}
        take={dataState.take}
        // filterOperators={filterOperators}
        onDataStateChange={dataStateChange}
        data={dataResult}
        total={totalResult}
        className="kendo_reports_recruiterActivity"
      >
        <GridNoRecords>
          {isSearching ? recruiterActivitySkeleton() : "No results found !"}
        </GridNoRecords>

        {
          fields.map((elem) => {
            return (
              <GridColumn
                key={elem}
                field={elem.field}
                title={elem.title}
                width="180px"
                filterable={false}
                cell={elem.onClick ? (event) => clickableColumns(event, elem.code) : undefined} // pass code to clickableColumns
              />
            )
          })
        }
      </GridDiv>
    </>
  );
};

export default RecruitersActivity;
