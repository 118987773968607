import { useContext, useEffect, useState } from 'react';
import Header from '../../components/headerAdmin/Header'
import Footer from '../../components/adminFooter/Footer'
import RequisitionDetailsBody from './RequisitionDetailsBody.js';
import "../RequisitionDetails/RequisitionDetails.css"
import { UserTypeContext } from '../../AppRoute';
import checkRequisitionExists from "../../apis/checkRequisitionExists/checkRequisitionExists"
import BasicHeader from '../../components/basicHeader/BasicHeader';
import { CircularProgress } from '@material-ui/core';

function RequisitionDetails(props) {
  const { match } = props;
  const requisitionId = match?.params?.id
  const { userType, userRecords } = useContext(UserTypeContext);
  const idContextTest = props?.location?.state?.id ? props?.location?.state?.id : userRecords?.id;
  const userRecruitmentpartnerId = props?.location?.state?.userRecruitmentpartnerId ? props?.location?.state?.userRecruitmentpartnerId : userRecords?.recruitmentpartnerId;
  const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : userRecords?.role?.roleName;
  const [checkRequisition, setCheckRequisition] = useState([])
  const [isLoading, setIsLoading]= useState(false)
  
  const requisitionCheck = async () => {
    setIsLoading(true)
    let payload = {
      requisitionId: requisitionId,
      userRecruitmentpartnerId: userRecruitmentpartnerId,
      userType: userType,
      userId: idContextTest,
      validation: "RequisitionCheckValidation"
    }
    let response = await checkRequisitionExists(payload);
    let data = response.data.data
    setCheckRequisition(data)
    setIsLoading(false)
  }

  useEffect(() => {
    requisitionCheck()
  }, [])

  return (
    <div className='mainContainer_requisitionDetails'>
      <div className='topContainer_requisitionDetails'>
        <Header />
        {(checkRequisition.length > 0) || userType ==="Admin" ||  userType === "MIS Operator" || userType === "Organization Admin" ?
          <RequisitionDetailsBody
            location={{state:props?.location?.state,requisitionId}}
          />
          : <>
          {isLoading ? <div className = "loader_requisitionDetails"><CircularProgress color="inherit" size={50} /></div> : 
            <div className='Unauthorized_container'>
              <BasicHeader />
              <div className="row padding_80 text-center">
                <div className='UnAuthorized_contain'>
                  <p className="fs-semi-bold fs-40 f-color authorize">
                    You Are Not Authorized To Visit This Requistion
                  </p>
                </div>
              </div>
            </div>
          }
          </>}
      </div>
      <Footer />
    </div>
  )
}

export default RequisitionDetails;



