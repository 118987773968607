import React, { useEffect, useState, useContext } from 'react'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Header from "../../components/headerAdmin/Header";
import { process } from "@progress/kendo-data-query";
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import BasicHeader from "../../components/basicHeader/BasicHeader";
import { Grid as GridDiv, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { Autocomplete } from '@material-ui/lab';
import department from './icons/department.png';
import { getRecruiterDetails, getRecruitmentLeaderbyorg } from "../../apis/organisationAdminAPI/manageDivision"
import getAllTeamLeaderByOrg from "../../apis/teamLeaderbyOrg/getAllTeamLeaderByOrg"
import addTeam from "../../apis/addTeam/addTeam"
import getTeamDetails from "../../apis/teambyOrganizationId/getAllTeamByOrgId"
import updateTeamDetail from '../../apis/updateTeam/updateTeamDetails';
import { UserTypeContext } from "../../AppRoute";
import PeopleIcon from '@material-ui/icons/People';
import Skeleton from '@material-ui/lab/Skeleton';
import "./manageTeam.css"
import checkExistsRequisition from '../../apis/checkRequisitionExists/checkRequisitionExists';


const useStyles = makeStyles((theme) => ({
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
}))

const ManageTeam = (props) => {
    const classes = useStyles()
    const { userType, userRecords } = useContext(UserTypeContext);
    const userRecruitmentpartnerId = userType === "Admin" ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId
    const idContextTest = userType === "Admin" ? props?.location?.state?.id : userRecords?.id
    const userRole = userType === "Admin" ? props?.location?.state?.userRole : userRecords?.role?.roleName
    const userRecordData = userType === "Admin" ? props?.location?.state?.userRecords : userRecords
    const history = useHistory();

    const [openDialog, setOpenDialog] = useState(false);
    const [editedRow, setEditedRow] = useState({});
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)
    const [teamInfo, setTeamInfo] = useState({
        teamName: "",
        recruitmentLeader: "",
        teamLeader: "",
        selectedRecruiterId: [],
        selectedRecruiter: [],
        currentRecruiterValue: [],
        isActive: { name: "True", value: true },
    })
    const [allTeam, setAllTeam] = useState([])
    const [result, setResult] = useState(allTeam);
    const [activeDropDown, setActiveDropDown] = useState([
        { name: "True", value: true },
        { name: "False", value: false },
    ]);
    const [isActiveFieldDisabled, setIsActiveFieldDisabled] = useState(true)
    const [recruitmentLeaderOptions, setRecruitmentLeaderOptions] = useState([])
    const [teamLeaderOptions, setTeamLeaderOptions] = useState([])
    const [recruiterOptions, setRecruiterOptions] = useState([]);
    const [isSearching, setIsSearching] = useState(true)
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleOpenDialog = () => {
        setOpenDialog(true);
        setEditedRow({});
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditedRow({});
        setTeamInfo({
            teamName: "",
            recruitmentLeader: "",
            teamLeader: "",
            selectedRecruiterId: [],
            selectedRecruiter: [],
            isActive: { name: "True", value: true },
        })
        setIsActiveFieldDisabled(true);
    };

    const handelEditOpenDialog = () => {
        setOpenDialog(true);
    }

    const openDialogRecruiter = () => {
        setIsDialogOpen(true);
    }

    const closeDialogRecruiter = () => {
        setIsDialogOpen(false);
    }

    const [dataState, setDataState] = useState({
        skip: 0,
        take: 10,
        filter: {
            logic: "and",
            filters: [
                {
                    field: "isActive",
                    operator: "eq",
                    value: true,
                },
            ],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });

    const dataStateChange = (event) => {
        setResult(process(allTeam, event.dataState));
        setDataState(event.dataState);
    };

    useEffect(() => {
        setResult(process(allTeam, dataState))
    }, [allTeam])

    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: {
                userType: userType,
                recruitmentPartnerId: userRecruitmentpartnerId,
                id: idContextTest,
                userRecords: userRecordData,
                roleName: userRole
            }
        });
    }

    const fetchRecruitmentLeaderDetails = async () => {
        let payload = {
            recruitmentpartnerId: userRecruitmentpartnerId,
            manageDivision: false
        }
        try {
            const recruitmentLeaderDetails = await getRecruitmentLeaderbyorg(payload)
            if (recruitmentLeaderDetails.status === 200) {
                setRecruitmentLeaderOptions(recruitmentLeaderDetails.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchTeamLeaderDetails = async () => {
        let payload = {
            recruitmentpartnerId: userRecruitmentpartnerId
        }
        try {
            let teamLeaderDetails = await getAllTeamLeaderByOrg(payload)
            if (teamLeaderDetails.status === 200) {
                setTeamLeaderOptions(teamLeaderDetails.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }



    const fetchRecruiterDetails = async () => {
        let payload = {
            recruitmentpartnerId: userRecruitmentpartnerId
        }
        try {
            let recruiterDetails = await getRecruiterDetails(payload);

            if (recruiterDetails.status === 200) {
                setRecruiterOptions(recruiterDetails.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchAllTeamDetails = async () => {
        setIsSearching(true)
        setAllTeam([])
        let payload = {
            recruitmentpartnerId: userRecruitmentpartnerId
        }
        try {
            const allTeamDetails = await getTeamDetails(payload)
            if (allTeamDetails.status === 200) {
                setAllTeam(allTeamDetails.data.data)
            }
            setIsSearching(false)
        } catch (error) {
            setIsSearching(false)
            setAllTeam([])
            console.log(error)
        }
    }

    useEffect(() => {
        if (userRecruitmentpartnerId) {
            fetchRecruiterDetails();
            fetchRecruitmentLeaderDetails()
            fetchTeamLeaderDetails()
            fetchAllTeamDetails()
        }
    }, [])

    const handleTeamName = (e) => {
        if (e.target.value) {
            setTeamInfo({ ...teamInfo, teamName: e.target.value })
        } else {
            setTeamInfo({ ...teamInfo, teamName: "" })
        }
    }

    const handleRecruitmentLeader = (e, value) => {
        setTeamInfo({ ...teamInfo, recruitmentLeader: value })
    }

    const handleTeamLeader = (e, value) => {
        setTeamInfo({ ...teamInfo, teamLeader: value })
    }

    const handleRecruiterChange = (e, value) => {
        let latestValue = value[value.length - 1];
        if (value) {
            if(latestValue.id){
                setTeamInfo({ ...teamInfo, selectedRecruiter: value });
            }
        } else {
            setTeamInfo({ ...teamInfo, selectedRecruiter: [] });
        }
    };

    const deleteRecruiterOptionHandler = async (e, option) => {
        try {
            let payload = {
                recruiterId: option.id,
                recruitmentpartnerId: userRecruitmentpartnerId,
                validation: "Team"
            }
            const dataRes = await checkExistsRequisition(payload);

            if (dataRes?.status === 200 && dataRes?.data?.data > 0) {
                NotificationManager.error(
                    `${dataRes?.data?.message}`,
                    "error",
                    2500);
            } else if (dataRes.status === 400 && dataRes.errorCode === 400) {
                const errorMessage = dataRes.data.message;
                NotificationManager.error(errorMessage, "Error", 2500);

            } else {
                const matchRecruiter = teamInfo.selectedRecruiter.filter((item) => item.id === option.id)
                const newRecruiterOptions = teamInfo.selectedRecruiter.filter((item) => item.id !== option.id)
                const newRecruiterid = teamInfo.selectedRecruiterId.filter((item) => item !== option)
                setTeamInfo({ ...teamInfo, selectedRecruiter: newRecruiterOptions, selectedRecruiterId: newRecruiterid })
                setRecruiterOptions([...recruiterOptions, ...matchRecruiter])
            }
        } catch (error) {
            NotificationManager.error("Something Went Wrong!", "error", 2500)
        }
    }

    const handleActiveChange = async (e, value) => {
        if (value) {
            setTeamInfo({ ...teamInfo, isActive: value })
        } else {
            setTeamInfo({ ...teamInfo, isActive: "" })
        }

    }

    const handleSubmit = async () => {
        if (teamInfo.teamName === "" || teamInfo.recruitmentLeader === ""|| teamInfo.selectedRecruiter.length === 0 || 
            teamInfo.isActive === ""
        ) {
            return NotificationManager.error(
                "Please fill in all the fields",
                "Error",
                2500
            );
        } else {
            setIsUserDataUpdating(true)
            let selectedRecruitersIds = [];
            for (let i = 0; i < teamInfo.selectedRecruiter.length; i++) {
                let item = teamInfo.selectedRecruiter[i];
                selectedRecruitersIds.push(item.id);
            }

            let payload = {
                teamName: teamInfo.teamName,
                recruitmentpartnerId: userRecruitmentpartnerId,
                recruiterLeaderId: teamInfo.recruitmentLeader.id,
                teamLeaderId: teamInfo?.teamLeader?.id || null,
                recruiterId: selectedRecruitersIds,
                isActive: teamInfo.isActive.value,
                updatedBy: idContextTest,
                oldTeamLeaderId:editedRow.teamLeaderId,
                oldRecruitmentLeaderId: editedRow.recruiterLeaderId,
                isTeamLeaderChange:false,
                isRecruitmentLeaderChange:false,
                recruitmentLeaderEmail : teamInfo.recruitmentLeader.email,
                userRecruitmentpartnerId:userRecruitmentpartnerId,
                userId:idContextTest,
            }
            if (Object.keys(editedRow).length > 0) {
                payload.id = editedRow._id
                if (editedRow.recruiterLeaderId !== teamInfo.recruitmentLeader.id) {
                    payload.isRecruitmentLeaderChange = true;
                }
                if (teamInfo.teamLeader == null) {
                    payload.isTeamLeaderChange = true;
                }

                if(teamInfo?.teamLeader?.id)
                {
                if (editedRow.teamLeaderId !== teamInfo.teamLeader.id) {
                    payload.isTeamLeaderChange = true;
                }

            }
                try {
                    let response = await updateTeamDetail(payload);
                    if (response.status === 200) {
                        NotificationManager.success(
                            `${response.data.message}`,
                            "Success",
                            2500
                        );
                        handleCloseDialog();
                        fetchRecruiterDetails();
                        fetchRecruitmentLeaderDetails()
                        fetchTeamLeaderDetails()
                        fetchAllTeamDetails()
                    }      
                    else if (response.status === 400 && response.errorCode === 400) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    if (error.response.status === 400) {
                        NotificationManager.error(error?.response?.data?.message, "Error", 2500);
                        setIsUserDataUpdating(false);
                    }
                    else {
                        NotificationManager.error('Something went wrong', "Error", 2000);
                    }
                }

            } else {
                payload.createdBy = idContextTest;
                try {
                    const addTeamDetails = await addTeam(payload)
                    if (addTeamDetails.status === 200) {
                        NotificationManager.success(
                            `${addTeamDetails.data.message}`,
                            "Success",
                            2500
                        );
                        handleCloseDialog();
                        fetchRecruiterDetails();
                        fetchRecruitmentLeaderDetails()
                        fetchTeamLeaderDetails()
                        fetchAllTeamDetails()
                    }
                    else if (addTeamDetails.status === 400 && addTeamDetails.errorCode === 400) {
                        const errorMessage = addTeamDetails.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    if (error.response.status === 400) {
                        NotificationManager.error(error?.response?.data?.message, "Error", 2500);
                        setIsUserDataUpdating(false);
                    }
                }
            }
            setIsUserDataUpdating(false)
        }
    }

    const handleEdit = async (rowData) => {
        setTeamInfo({
            ...teamInfo,
            teamName: rowData.teamName,
            recruitmentLeader: rowData.recruiterLeader,
            teamLeader: rowData.teamLeader,
            selectedRecruiterId: rowData.recruiterId,
            selectedRecruiter: rowData.recruiter,
            isActive: {
                name: rowData.isActive ? "True" : "False",
                value: rowData.isActive
            }
        });
        setEditedRow(rowData);
        handelEditOpenDialog();
        try {
            let payload = {
                teamId: rowData._id,
                recruitmentpartnerId: userRecruitmentpartnerId,
                validation: "Team",
            }
            const data = await checkExistsRequisition(payload);
            const requisitionExists = data?.data?.data;
            if (requisitionExists > 0 && rowData) {
                setIsActiveFieldDisabled(true)
            }
            else {
                setIsActiveFieldDisabled(false)
            }
        } catch (error) {
            NotificationManager.error("Something Went Wrong!", "error", 2500)
        }
    };

    const getSkeleton = () => {
        let arr = []
        for (let i = 0; i < 16; i++) {
            arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="35px" />)
        }
        return arr;
    }



    return (
        <>
            {(userType === "Organization Admin" || userType === "MIS Operator" || (userType === 'Admin' && userRole === 'Organization Admin') || (userType === 'Admin' && userRole === 'MIS Operator')) ?
                <>
                    <NotificationContainer />
                    <Header />
                    <div className="section-wrapper">
                        <div className="container w1200 flex-to-footer">
                            <div className="height_adjust mt-110 manage-client-main">
                                <div className="header-client-manager">
                                    <div className="header-image-manage-client">
                                        <Typography className="manage-client-main-head">Manage Team</Typography>
                                    </div>
                                    {userType === "Admin" ? "" :
                                        <Button className="add-button-manageclient"
                                            onClick={handleOpenDialog}
                                            startIcon={<PeopleIcon />}
                                        >
                                            Add Team
                                        </Button>
                                    }
                                </div>
                                <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                                    <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                                </div>
                                <div className='tableGrid_team'>
                                    <GridDiv
                                        filterable={true}
                                        filter={dataState.filter}
                                        sort={dataState.sort}
                                        sortable={true}
                                        resizable={true}
                                        reorderable={true}
                                        pageable={{
                                            pageSizes: [10, 20, 50, 100],
                                            info: true,
                                            previousNext: true,
                                            buttonCount: 10
                                        }}
                                        skip={dataState.skip}
                                        take={dataState.take}
                                        data={result}
                                        onDataStateChange={(e) => dataStateChange(e)}
                                    >
                                        <GridNoRecords>
                                            {isSearching ? getSkeleton() : "No results found !"}
                                        </GridNoRecords>
                                        <Column
                                            field="teamName"
                                            title="Team"
                                            width="180px"
                                            filterable={true}
                                        />
                                        <Column
                                            field="recruiterLeader.email"
                                            title="Recruitment Leader"
                                            width="220px"
                                            filterable={true}
                                        />
                                        <Column
                                            field="teamLeader.email"
                                            title="Team Leader"
                                            width="220px"
                                            cell={(props) => {
                                                console.log(props.dataItem.teamLeader)
                                                return (
                                                    props.dataItem.teamLeader.email?
                                                    <td>
                                                        {props.dataItem.teamLeader.email}
                                                    </td>
                                                    :
                                                    <td>
                                                        N/A
                                                    </td>

                                                );
                                            }}
                                            filterable={true}
                                        />
                                        <Column
                                            field='teamSize'
                                            title="Team Size"
                                            width="150px"
                                            filterable={true}
                                        />

                                        <Column
                                            field="isActive"
                                            title="Is Active"
                                            width="150px"
                                            filter={"boolean"}
                                            cell={(props) => {
                                                const active = props.dataItem.isActive ? "True" : "False";
                                                return (
                                                    <td>
                                                        {active}
                                                    </td>
                                                );
                                            }}
                                            filterable={true}
                                        />
                                        <Column
                                            title="Action"
                                            filterable={false}
                                            width="200px"
                                            cell={(props) => {
                                                return (

                                                    <td >
                                                        <div className='actionbutton'>
                                                            <button
                                                                className='editBtn'
                                                                onClick={() => handleEdit(props.dataItem)}
                                                                disabled={userType === 'Admin' ? true : false}
                                                            >
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </td>
                                                );
                                            }}
                                        />
                                    </GridDiv>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="sm"
                fullWidth={true}
                className="add-team"
            >
                <div className="team_dialog_modal" >
                    <div className='team-dialog-TitleContainer'>
                        <PeopleIcon className="iconStyle" />
                        <span className="dialog-title-text">{Object.keys(editedRow).length > 0 ? 'Edit Team' : 'Add Team'}</span>
                    </div>
                </div>
                <DialogContent className="team-dialog-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className='field-title-team-dialog'>Team Name*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className='input-field-team-dialog'
                                placeholder="Enter Team Name"
                                value={teamInfo.teamName}
                                onChange={handleTeamName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='field-title-team-dialog' >Recruitment Leader*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                options={recruitmentLeaderOptions}
                                getOptionLabel={(option) => option.email}
                                value={teamInfo.recruitmentLeader}
                                disableClearable
                                filterSelectedOptions
                                onChange={handleRecruitmentLeader}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Recruitment Leader"
                                    />
                                )}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='field-title-team-dialog' >Team Leader</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                options={teamLeaderOptions }
                                getOptionLabel={(option) => option.email}
                                value={teamInfo.teamLeader}
                                filterSelectedOptions
                                onChange={handleTeamLeader}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Team Leader"
                                    />
                                )}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='field-title-team-dialog'>Recruiter*</Typography>
                            <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    id="multiple-recruiter-tags"
                                    multiple
                                    freeSolo
                                    filterSelectedOptions
                                    options={recruiterOptions}
                                    value={teamInfo.selectedRecruiter}
                                    getOptionLabel={(option) => option.email}
                                    onChange={handleRecruiterChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Recruiters"
                                            style={{ backgroundColor: '#fff', color: '#000', borderRadius: 4, borderColor: '#31b3f8' }}
                                        />
                                    )}
                                    renderTags={(value, getTagProps) => {
                                        if (value.length > 3) {
                                            const firstTwo = value.slice(0, 3);
                                            const remainingCount = value.length - 3;
                                            return (
                                                <>
                                                    {firstTwo.map((option, index) => (
                                                        <Chip
                                                            label={option.email}
                                                            {...getTagProps({ index })}
                                                            onDelete={(e) => deleteRecruiterOptionHandler(e, option)}
                                                        />
                                                    ))}
                                                    <Chip
                                                        label={`+${remainingCount} more`}
                                                        onClick={openDialogRecruiter}
                                                    />
                                                </>
                                            );
                                        }
                                        return (
                                            value.map((option, index) => (
                                                <Chip
                                                    label={option.email}
                                                    {...getTagProps({ index })}
                                                    onDelete={(e) => deleteRecruiterOptionHandler(e, option)}
                                                />
                                            ))
                                        );
                                    }}
                                />

                                <Dialog open={isDialogOpen} onClose={closeDialogRecruiter} fullWidth maxWidth="md" className='team_dialog'>
                                    <div className="team_dialog_modal" >
                                        <div className='team-dialog-TitleContainer'>
                                            <span className="dialog-title-text">Selected Recruiters</span>
                                        </div>
                                    </div>
                                    <DialogContent>
                                        <Autocomplete
                                            id="multiple-recruiter-tags-dialog"
                                            multiple
                                            freeSolo
                                            filterSelectedOptions
                                            options={recruiterOptions}
                                            value={teamInfo.selectedRecruiter}
                                            getOptionLabel={(option) => option.email}
                                            onChange={handleRecruiterChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select Recruiters"
                                                />
                                            )}
                                            renderTags={(value, getTagProps) => {
                                                return (
                                                    value.map((option, index) => (
                                                        <Chip
                                                            label={option.email}
                                                            {...getTagProps({ index })}
                                                            onDelete={(e) => deleteRecruiterOptionHandler(e, option)}
                                                        />
                                                    ))
                                                );
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <button onClick={closeDialogRecruiter} className="close_button_team">Close</button>
                                    </DialogActions>
                                </Dialog>


                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='field-title-team-dialog'>Is Active*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                options={activeDropDown}
                                getOptionLabel={(option) => option?.name}
                                value={teamInfo.isActive}
                                disabled={editedRow ? isActiveFieldDisabled : false}
                                onChange={handleActiveChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Active"
                                    />
                                )}

                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className='dialog-actionbutton-team'>
                    <button onClick={handleCloseDialog} className="cancel-button-team">
                        Cancel
                    </button>
                    <button
                        className={isUserDataUpdating ? 'updateButton_team_disabled' : 'updateButton-team'}
                        onClick={handleSubmit}
                        disabled={isUserDataUpdating}
                    >

                        <span>Save</span>
                        {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton_team" />) : null}


                    </button>
                </DialogActions>
            </Dialog>
            <NotificationContainer />
        </>
    )
}

export default ManageTeam