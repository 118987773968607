import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Badge, Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Loader } from '@progress/kendo-react-indicators';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import approveRecruiterFromRevoke from "../../apis/recruiters/approveRecruiters"
import "./recruiters.css"
const AllRevokedRecruitersKendoTable = ({ allRevokedRecruiters, isDataLoaded, setDataStateNew, dataStateNew,totalCount }) => {
  const history = useHistory();

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [result, setResult] = React.useState(allRevokedRecruiters?.result || []);

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [selectedDataItem, setSelectedDataItem] = useState(null);
  const [unRevoked, setUnRevoked] = useState([])
  useEffect(() => {

    for (let i = 0; i < allRevokedRecruiters.length; i++) {
      let agencyName = "agencyName"
      if (allRevokedRecruiters[i].agency) {
        allRevokedRecruiters[i][agencyName] = allRevokedRecruiters[i]?.agency.agencyName
      }
      else allRevokedRecruiters[i][agencyName] = "N/A"
    }

  }, [allRevokedRecruiters])


  const dataStateChange = (event) => {
    setResult(process(allRevokedRecruiters, event.dataState));
    setDataState(event.dataState);
  };

  const pageChange = (e) => {
    setDataStateNew({skip: e.page.skip, take: e.page.take });
  }


  const handlePhoneNumber = (props) => {
    return (
      <td>{props.dataItem.phoneNumber ? props.dataItem.phoneNumber : " "}</td>
    );
  };

  const stylesforBadge = {
    textAlign: "center",
  };

  const handleAction = (props) => {
    return (
      <td>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleRowClick(e, props.dataItem)}
        >
          Go To Profile
        </Button>
      </td>
    );
  };

  const FilterResult = (data) => {
    if(!data?.data?.length>0){
      return []
    }
    return data?.data?.filter((item) => !unRevoked.includes(item.id))
  }

  const openModel = (event, dataItem) => {
    setSelectedDataItem(dataItem);
    setmodalIsOpen(true);
  };

  const closeModal = () => {
    setmodalIsOpen(false);
  }

  const saveHandler = async () => {
    let payload = {
      userid: selectedDataItem.id,
      unrevoke: true
    }
    try {
      const reponse = await approveRecruiterFromRevoke(payload)
      NotificationManager.success(
        `${reponse?.data?.message}`,
        "Success",
        2500
      );
      setUnRevoked((prev) => [...prev,selectedDataItem.id])
    } catch (error) {
      NotificationManager.error(
        `${error?.data?.message || "something went wrong"}`,
        "error",
        2500
      );
    }
    setmodalIsOpen(false);

  };


  const handleOrganization = (props) => {
    return (
      <td>
        {props.dataItem.organizationnameduringsignup
          ? props.dataItem.organizationnameduringsignup
          : " "}
      </td>
    );
  };

  const handleCountry = (props) => {
    return (
      <td>
        {props.dataItem?.profile?.locationCountry
          ? JSON.parse(props.dataItem?.profile?.locationCountry).name
          : " "}
      </td>
    );
  };

  let columns = [
    { id: 0, name: "expanded", title: " ", filterable: true, width: "200px" },
    {
      id: 1,
      name: "action",
      title: "Action",
      cell: handleAction,
      filterable: false,
      width: "150px",
    },
    {
      id: 2,
      name: "action",
      title: "Action",
      filterable: false,
      width: "150px",
      cell: (props) => (
        <td>
          <Button
            variant="contained"
            color="primary"
            className="unrevokedButton"
            onClick={(e) => openModel(e, props.dataItem)}
          >
            UnRevoke
          </Button>
        </td>
      ),
    },
    {
      id: 3,
      name: "firstName",
      title: "First Name",
      filterable: true,
      width: "200px",
    },
    {
      id: 4,
      name: "lastName",
      title: "Last Name",
      filterable: true,
      width: "200px",
    },
    { id: 5, name: "email", title: "Email", filterable: true, width: "350px" },
    {
      id: 6,
      name: "organization",
      title: "Recruitment Organization",
      cell: handleOrganization,
      filterable: true,
      width: "200px",
    },
    {
      id: 7,
      name: "agencyName",
      title: "Agency",
      filterable: true,
      width: "200px",
    },
    {
      id: 8,
      name: "phoneNumber",
      title: "Phone Number",
      filterable: true,
      cell: handlePhoneNumber,
      filter: "numeric",
      format: "n0",
      width: "200px",
    },
    {
      id: 9,
      name: "locationCountry",
      filterable: true,
      title: "Country",
      cell: handleCountry,
      width: "200px",
    },

  ];

  const handleRowClick = (rowData = {}, data) => {
    history.push({
      pathname: "/recruiter-talent-community",
      state: { id: data.id },
    });
  };


  useEffect(() => {


    setResult(process(allRevokedRecruiters, dataState));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRevokedRecruiters]);

  return (
    <>
        <Grid
          sort={dataState.sort}
          sortable={true}
          style={{
            maxHeight: "75vh",
            width: "100%",
            overflow: "hidden"
          }}
          filter={dataState.filter}
          pageable={{
            buttonCount: 10,
            info: true,
            previousNext: true,
            pageSizes: true,
          }}
          resizable={false}
          skip={dataStateNew.skip}
          take={dataStateNew.take}
          data={FilterResult(result)}
          total={totalCount}
          reorderable={true}
          onDataStateChange={dataStateChange}
          onPageChange={(e) => pageChange(e)}
        >
          {
            !isDataLoaded && (
              <GridNoRecords>
                <div className='col-4'>
                  <br />
                  <Loader size='large' themeColor="info" type="converging-spinner" />
                </div>
              </GridNoRecords>
            )}
          {columns?.slice(2).map((column) => {
            return (
              <Column
                key={column.id}
                field={column.name}
                title={column.title}
                cell={column.cell}
                filter={column.filter}
                filterable={column.filterable}
                width={column.width}
              />
            );
          })}
        </Grid>
      <Dialog
        onClose={closeModal}
        open={modalIsOpen}
        aria-labelledby="customized-dialog-title"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent >
          <div className="fs-semi-bold f-color fs-18">Do you want to UnRevoke this user?</div>
        </DialogContent>
        <DialogActions>
          <div className='button_Container_CreateRequisition'>
            <Button variant="contained" color="primary" className="ButtonSave_createRequisition_dialog" onClick={saveHandler}
            >
              Yes
            </Button>
            <Button variant="contained" color="secondary" className='ButtonCancel_createRequisition' onClick={closeModal}
            >
              No
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>



  );
};

export default AllRevokedRecruitersKendoTable;
